export const track_pageview = (screenName) => {
    return {
        eventName: "page_view",
        eventParameters: {
            page_title: screenName,
        },
    }
};

export const track_home_screen = track_pageview('home');
export const track_not_found_screen = track_pageview('not_found');
export const track_rooms_screen = track_pageview('rooms');