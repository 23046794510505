import React from "react";
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./analytics/store";

import Home from "./screens/home";
import NotFound from "./screens/notFound";
import Rooms from "./screens/rooms";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/rooms/" element={<Rooms />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);
