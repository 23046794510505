import { Navigate } from "react-router-dom";
import "../css/app.css";

import store from "../analytics/store";
import { track_not_found_screen } from "../analytics/events";

function NotFound() {
  store.getState().track(track_not_found_screen);
  return <Navigate to={"/"} />;
}

export default NotFound;
