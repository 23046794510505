import AppContainer from "../components/container.js"
import "../css/app.css";

import store from "../analytics/store";
import { track_rooms_screen } from "../analytics/events";

function Home(props) {
  store.getState().track(track_rooms_screen);
  return (
    <AppContainer>
        <div>
            <h1>Rooms</h1>
        </div>
    </AppContainer>
  );
}

export default Home;
