import AppContainer from "../components/container.js"
import "../css/app.css";
import logo from "../images/sickrecords_vinyl_transparent.png"

import store from "../analytics/store";
import { track_home_screen } from "../analytics/events";

function Home(props) {
  store.getState().track(track_home_screen);
  return (
    <AppContainer>
      <div className="intro">
        <div className="logo">
            <img id="vinyl" src={logo} alt="Sick Records" width="220" height="auto" />
        </div>
        <div className="what-we-do">
          <h1>&raquo; BOUTIQUE RECORD LABEL</h1>
          <h1>&raquo; WRITER'S ROOMS</h1>
          <h1>&raquo; JHB x LA</h1>
        </div>
      </div>
    </AppContainer>
  );
}

export default Home;
