import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyA884HOL4oX9QoPHRPe6Fzy_WaDHgVLy3M",
  authDomain: "sick-records-web.firebaseapp.com",
  projectId: "sick-records-web",
  storageBucket: "sick-records-web.appspot.com",
  messagingSenderId: "55074300635",
  appId: "1:55074300635:web:6d4ed709c4cd3ea60ce2fe",
  measurementId: "G-7V99J0SH27"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const track = (event, userProps) => {
  console.log(
    `TRACKING ${event.eventName} with ${JSON.stringify(event.eventParameters)}`
  );
  if (userProps) {
    console.log(
      `SETTING USER PROPERTIES ${JSON.stringify(userProps)}`
    );
    setUserProperties(analytics, userProps);
  }
  logEvent(
    analytics,
    event.eventName,
    event.eventParameters || {}
  );
};

export default track;
