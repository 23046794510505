import React from "react";
import "../css/app.css";
import "../css/app.footer.css";

class Footer extends React.Component {
  render() {
    return (
      <div className="App-footer">
        <i className="font-thin font-small">&copy; Sick Records 2024</i>
      </div>
    );
  }
}

export default Footer;
